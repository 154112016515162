import React, { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { XCircleIcon } from "@heroicons/react/solid";
import { DashboardPage } from "../lib/features/auth/DashboardPage";
import { FormInput } from "../lib/entities";
import { useLoginMutation } from "@/lib/services/login";
import { t, Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import Logo from "../public/google_logo_icon.svg";
interface LoginFormTarget extends EventTarget {
  email: FormInput;
  password: FormInput;
}

interface LoginResponseMutation {
  data: {
    token: string;
  };
  error: any;
}

const Login: DashboardPage = () => {
  const router = useRouter();
  const [makeLogin] = useLoginMutation();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loginUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as LoginFormTarget;

    setIsLoading(true);
    setError("");

    const result = (await makeLogin({
      username: target.email.value,
      password: target.password.value,
    })) as LoginResponseMutation;

    setIsLoading(false);

    if (result.error) {
      if (
        result.error.status === 400 &&
        result.error.data.non_field_errors?.includes("google_required")
      ) {
        setError(t`Google authentication required`);
        return;
      }
      setError(t`Unable to log in with provided credentials.`);
      return;
    }
    router.push("/");
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center items-center flex-col">
          <Image
            src="/images/ros-logo-blanco.svg" // Route of the image file
            height={60} // Desired size with correct aspect ratio
            width={500} // Desired size with correct aspect ratio
            objectFit={"contain"}
            alt="Logo Rendalo"
          />
          {error && (
            <div className="rounded-md bg-red-50 p-4 mt-4 w-full">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-bg py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <h2 className="mt-2 mb-6 text-center text-3xl font-extrabold text-gray-900">
              <Trans>Log in</Trans>
            </h2>
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={loginUser}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    cy-data="email-input"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-accent focus:border-accent sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  <Trans>Password</Trans>
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    cy-data="password-input"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-accent focus:border-accent sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center invisible">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link href="/password-recovery">
                    <a className="font-medium text-accent hover:text-orange-500">
                      <Trans>Recover your password</Trans>
                    </a>
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  cy-data="login-btn"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                  bg-gradient-to-r from-primary to-fiery-rose hover:from-fiery-rose hover:to-primary
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  <Trans>Log in</Trans>
                </button>
                <a
                  href={`${process.env.NEXT_PUBLIC_API_HOST}/users/v100/google/redirect`}
                  className="mt-4 w-full flex justify-center items-center gap-2 py-2 px-4 border border-transparent rounded-md shadow-md text-sm text-slate-900 
                  bg-gradient-to-r from-google to-google-hover
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  <Image
                    width={40}
                    height={30}
                    src={Logo}
                    alt="RendaloMaq logo"
                  />{" "}
                  <Trans>Login with google</Trans>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
Login.auth = false;
export default Login;
